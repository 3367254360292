import { useState } from 'react';
import { Xmark } from '@pelando/fontawesome/icons';
import { useModal } from '@pelando/components';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { removeDealOnboardingStageFromLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { DealStepTypes } from '../consts';
import WelcomeStep from '../WelcomeStep';
import { StepsModalContainer } from '../../NewOnboarding/OnboardingModal/style';
import {
  CloseButtonIcon,
  MobileProgressBarDeal,
  MobileProgressBarDealContainer,
  StepsModalProgressBarDeal,
} from './style';
import VotingStep from '../VotingStep';

type DealOnboardingModalProps = {
  onFinished: () => void;
  stepTypeDefault?: DealStepTypes;
};

export function DealOnboardingModal({
  onFinished,
  stepTypeDefault,
}: DealOnboardingModalProps) {
  const onboardingModalSteps = [
    DealStepTypes.WELCOME_STEP,
    DealStepTypes.VOTING_STEP,
    DealStepTypes.COMUNITIES_STEP,
    DealStepTypes.VALUES_STEP,
  ];
  const { closeModal } = useModal();

  const { t } = useTranslation('onboarding');

  const stepIndexDefault = onboardingModalSteps.findIndex(
    (step) => step === stepTypeDefault
  );

  const [currentStep, setCurrentStep] = useState<DealStepTypes>(
    onboardingModalSteps[stepIndexDefault > 0 ? stepIndexDefault : 0]
  );

  const numberOfSteps = onboardingModalSteps.length;
  const currentStepIndex = onboardingModalSteps.findIndex(
    (step) => step === currentStep
  );

  const handleOnClickToContinue = () => {
    if (currentStepIndex < numberOfSteps - 1) {
      const nextStep = onboardingModalSteps[currentStepIndex + 1];
      setCurrentStep(nextStep);
    } else {
      onFinished();
    }
  };

  const handleOnboardingClose = () => {
    removeDealOnboardingStageFromLocalStorage();
    closeModal();
  };

  const StepsContents: {
    [index in DealStepTypes]: JSX.Element;
  } = {
    WELCOME_STEP: <WelcomeStep onClickContinue={handleOnClickToContinue} />,
    VOTING_STEP: <VotingStep onClickContinue={handleOnClickToContinue} />,
    COMUNITIES_STEP: <div>Comunities</div>,
    VALUES_STEP: <div>Values</div>,
  };

  const progressBarPercent = ((currentStepIndex + 1) / 4) * 100;

  return (
    <StepsModalContainer>
      <CloseButtonIcon
        icon={Xmark}
        aria-label={t('onboarding-modal-close-button-aria-label')}
        role="button"
        onClick={handleOnboardingClose}
      />

      <MobileProgressBarDealContainer>
        <MobileProgressBarDeal percent={progressBarPercent} />
      </MobileProgressBarDealContainer>

      {StepsContents[currentStep]}
      <StepsModalProgressBarDeal
        currentStepIndex={currentStepIndex}
        numberOfSteps={numberOfSteps}
        stepType={stepTypeDefault}
      />
    </StepsModalContainer>
  );
}
