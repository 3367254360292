import {
  CaptionHeavy,
  Colors,
  DealImage,
  DealStamp,
  DealVoteButton,
  H1Heavy,
  H2Heavy,
  H3Heavy,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  border-radius: 24px;
  border: 12px solid rgba(${Theme.colors.Lima}, 0.3);

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 24px;
  }
`;

export const CardImage = styled(DealImage)`
  && {
    width: 119px;
    margin-bottom: 12px;
    height: 142px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      width: 136px;
    }
  }
`;

export const VoteAndStoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    gap: 22px;
  }
`;

export const VoteButtonStyled = styled(DealVoteButton)`
  border-color: rgb(${Theme.colors.Brand});
`;

export const Title = styled.h1`
  color: rgb(${Theme.colors.Alpha});
  ${H3Heavy}
  width: 316px;
  height: 24px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
    height: 48px;
  }
`;

export const DealStampStyled = styled(DealStamp)`
  color: rgb(${Colors.Graphite});
  ${H1Heavy}

  & > small {
    ${H3Heavy}
    margin-right: 4px;
  }

  & > span {
    ${H1Heavy}
  }
`;

export const StoreLabel = styled.div`
  ${CaptionHeavy}
  display: flex;
  align-items: center;
  color: rgb(${Colors.Graphite});
  margin-left: 4px;

  i {
    font-size: 20px;
    margin-left: 4px;
  }
`;
