import { useEffect } from 'react';

import { addDealOnboardingStageLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { ChevronRight } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  FlamedLogo,
  TextContainer,
  TextOnboarding,
  WelcomeContainer,
} from './style';
import { DealStepTypes } from '../consts';
import { DealOnboardingContinueButton } from '../style';
import Translation from '../../Translation';

type WelcomeStepProps = {
  onClickContinue: () => void;
};

const WelcomeStep = ({ onClickContinue }: WelcomeStepProps) => {
  const { t } = useTranslation('onboarding');
  useEffect(() => {
    addDealOnboardingStageLocalStorage(DealStepTypes.WELCOME_STEP);
  }, []);

  return (
    <WelcomeContainer>
      <TextContainer>
        <FlamedLogo />
        <TextOnboarding>
          <Translation hasTag translation={t('onboarding-deals-welcome-title')}>
            Boas-vindas <br />
            ao <strong>Pelando!</strong>
          </Translation>
        </TextOnboarding>
      </TextContainer>
      <DealOnboardingContinueButton
        light
        icon={ChevronRight}
        iconPosition="right"
        onClick={onClickContinue}
      >
        {t('onboarding-button-start')}
      </DealOnboardingContinueButton>
    </WelcomeContainer>
  );
};

export default WelcomeStep;
