import { Display, Icon, MediaQuery, Theme } from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 12px;
  margin-top: 36px;
  gap: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    flex-direction: unset;
    margin-top: 12px;
    padding: 0 52px;
    gap: 48px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const VotingStepIcon = styled(Icon)`
  display: none;
  color: rgb(${Theme.colors.Brand});
  font-size: 50px;
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
  }
`;

export const Title = styled.h1`
  ${Display}
  color: rgb(${Theme.colors.Brand});
  margin-bottom: 16px;
`;

export const Subtitle = styled.p``;
