import {
  Colors,
  MediaQuery,
  StoreCaption,
  DealSaveButton,
  Icon,
  TextSkeleton,
  DealStoreLabel,
  Theme,
  CaptionLight,
} from '@pelando/components';
import styled, { css } from 'styled-components';

type ContainerProps = {
  active: boolean;
};

const inactiveStyle = css`
  ${StoreCaption} {
    color: inherit;
  }
  color: rgb(${Colors.Gray});
`;

const containerStyle = css`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const Container = styled.div<ContainerProps>`
  ${containerStyle}
  ${({ active }) => !active && inactiveStyle}
  padding: 12px 16px 0;
`;

export const DealSaveButtonViolet = styled(DealSaveButton)`
  i {
    color: rgb(${Theme.colors.Alpha});
  }
`;

export const TopRightContent = styled.div`
  display: flex;
  gap: 6px;
`;

export const TopLeftContent = styled.div`
  display: flex;

  gap: 6px;
  min-width: 200px;
`;

export const HeaderMobileBackButton = styled(Icon)`
  font-size: 24px;
  cursor: pointer;
`;

export const OptionsButtonContainer = styled.div`
  background-color: rgb(${({ theme }) => theme.colors.Juliet});
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(${({ theme }) => theme.colors.Golf});
  border-radius: 24px;
  height: 40px;
  width: 40px;
  padding-top: 10px;
`;

export const ButtonSkeleton = styled(TextSkeleton)`
  display: flex;
  flex-shrink: 0;
  margin-bottom: 4px;
  width: 40px;
  height: 40px;
  border-radius: 24px;
`;

export const ContainerSkeleton = styled.div`
  ${containerStyle}
  background-color: rgb(${({ theme }) => theme.colors.Juliet});
  padding: 12px 16px;
  align-items: center;
`;

export const CustomDealStoreLabel = styled(DealStoreLabel)`
  display: block !important;
`;

export const DefaultTextStore = styled.div`
  ${CaptionLight}
  display: flex;
  align-items: center;
  color: rgb(${Colors.Gray});
`;
