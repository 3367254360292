import { MediaQuery, Theme } from '@pelando/components';
import { css } from 'styled-components';

export const onboardingModalStyle = css`
  padding: 0;
  border-radius: 0;
  width: auto;
  height: 100vh;

  @media ${MediaQuery.SCREEN_MD_UP} {
    background-color: rgb(${Theme.colors.Brand});
    width: 850px;
    height: 600px;
  }
`;

export const onboardingDealModalStyle = css`
  padding: 0;
  border-radius: 0;
  width: auto;
  height: 100vh;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 850px;
    height: 600px;
  }
`;
