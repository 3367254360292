import { styled } from 'styled-components';
import {
  Colors,
  FontFamily,
  FontWeight,
  MediaQuery,
} from '@pelando/components';
import { OnboardingContainerSteps } from '../../NewOnboarding/style';

const HEADER_HEIGHT_ONBOARDING = 62;

export const WelcomeContainer = styled(OnboardingContainerSteps)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('/assets/onboardingDeal/welcome-image-mobile.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  margin-top: -${HEADER_HEIGHT_ONBOARDING}px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: absolute;
    background-image: url('/assets/onboardingDeal/welcome-image.png');
    height: 100%;
    width: 100%;
    margin-top: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 230px;
  color: rgba(${Colors.White});

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 150px;
  }
`;

export const FlamedLogo = styled.div`
  display: flex;
  background-image: url('/assets/onboardingDeal/icon-logo.png');
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 36px;
  background-size: cover;
  margin-bottom: 16px;
`;

export const TextOnboarding = styled.p`
  font-family: ${FontFamily.DM_SANS};
  font-weight: ${FontWeight.REGULAR};
  font-size: 2.5rem;
  line-height: 2.5rem;
`;
